import React from 'react';
import { Tabs } from 'antd';
import EngagementSettingComponent from './Engagement';


export default function EngagementSetting() {

    const { TabPane } = Tabs;
    function callback(key) {
        //   console.log(key);
    }
    return (
        <div>
            <Tabs defaultActiveKey="1" onChange={callback} size="large" centered>

                <TabPane tab="Engagement Page Setting" key="1">
                    <EngagementSettingComponent/>
                </TabPane>

            </Tabs>
        </div>
    )
}
