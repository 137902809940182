import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import JobApplicationViewMore from "./JobApplicationViewMore";
import { message } from "antd";
import api, { BASE_URL } from "../Helper/API";

const JobApplicantView = ({ applicationData, onCallBack }) => {
  const [applicationDetails, setApplicationDetails] = useState("");
  const [showDetails, setShowDetails] = useState(false);

  const handleOnClick = () => {
    setShowDetails((prevState) => !prevState);
  };

  console.log(
    "applicationDetails",
    applicationDetails && applicationDetails[0].job_details.title
  );

  const handleResumeDownload = async (applicant_id) => {
    let com_url = `${BASE_URL}/download-resume/${applicant_id}`;
    console.log("applicant_id is:----", applicant_id);
    try {
      // await api.get(`/download-resume/${applicationData._id}`);
      window.location.href = `${BASE_URL}/download-resume/${applicant_id}`;
      message.success("Downloading started!");
    } catch (error) {
      console.error(error);
    }
  };

  const handleApplicationDelete = async (applicant_id) => {
    try {
      let result = await api.delete(`/delete-application/${applicant_id}`);
      message.success(result.data.message);
      onCallBack();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // set job from the incoming props
    setApplicationDetails(applicationData.data);
  }, [applicationData]);
  return (
    // <>Hello</>
    <div className="container mt-3">
      <>
        <div className="container my-5 border border-black">
          <div className="box">
            <div className="row border-bottom border-dark">
              {/* top details */}

              <div className="col-8 my-auto">
                {!showDetails && (
                  <div className="row">
                    <div className="col-6">
                      <span
                        style={{
                          color: "gray",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Post:
                      </span>
                      <span
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {" "}
                        {applicationData.data[0].job_details.jobTitle}
                      </span>
                    </div>
                    <div className="col-6">
                      <span
                        style={{
                          color: "gray",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        Role:
                      </span>
                      <span
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {" "}
                        {applicationData.data[0].job_details.jobCategory}
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {/* view button */}
              <div className="col-4 text-end">
                <button
                  className="btn border-0 text-primary"
                  onClick={handleOnClick}
                >
                  {showDetails ? "View Less" : "View More"}
                </button>
              </div>

              {showDetails && (
                <div className="col-12">
                  <>
                    {applicationData && (
                      <JobApplicationViewMore
                        jobDetails={applicationData.data[0].job_details}
                      />
                    )}
                  </>
                </div>
              )}
            </div>
            <table className="table border border-black text-center my-3">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Ref ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th
                    style={{
                      maxWidth: "200px",
                    }}
                  >
                    Message
                  </th>
                  <th className="px-auto ">Resume</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {applicationDetails &&
                  applicationDetails.map((val) => {
                    return (
                      <tr>
                        <td>{new Date(val.createdAt).toLocaleString()}</td>
                        <td>{val.appli_ref_id}</td>
                        <td>{val.f_name + " " + val.l_name}</td>
                        <td>{val.email}</td>
                        <td>{val.phone}</td>
                        <td
                          style={{
                            maxWidth: "200px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 2,
                                height: "40px",
                                textOverflow: "ellipsis",
                                lineHeight: "20px",
                                textAlign: "justify",
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.WebkitLineClamp = "unset";
                                e.target.style.height = "auto";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.WebkitLineClamp = 2;
                                e.target.style.height = "40px";
                              }}
                            >
                              {val.con_message}
                            </div>
                          </div>
                          {/* {applicationData.con_message} */}
                        </td>
                        <td>
                          {/* <button
                      className="btn btn-info text-secondary-emphasis mx-2 fw-bold mt-2"
                      style={{ fontSize: "90%" }}
                    >
                      Preview
                    </button> */}
                          <button
                            className="btn btn-success  mx-2 fw-bold mt-2"
                            style={{ fontSize: "90%" }}
                            onClick={() => handleResumeDownload(val._id)}
                          >
                            Download
                          </button>
                        </td>
                        <td>
                          <button
                            className="btn btn-danger fw-bold mt-2"
                            style={{ fontSize: "90%" }}
                            onClick={() => handleApplicationDelete(val._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}

                {/* You can add more rows here if needed */}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </div>
  );
};

export default JobApplicantView;
