import React from 'react';
import { Tabs } from 'antd';
import CustomerSettingComponent from './Valuedcustomer';


export default function CustomerSetting() {

    const { TabPane } = Tabs;
    function callback(key) {
        //   console.log(key);
    }
    return (
        <div>
            <Tabs defaultActiveKey="1" onChange={callback} size="large" centered>

                <TabPane tab="Customer Page Setting" key="1">
                    <CustomerSettingComponent />
                </TabPane>

            </Tabs>
        </div>
    )
}
