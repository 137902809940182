import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";

import { Menu } from "antd";
import {
  MailOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  AppstoreOutlined,
  SettingOutlined,
  FileImageOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../assets/css/mystyle.css";
// import AboutSection from "./HomeSettingComponent/AboutSection"
import "../assets/css/mystyle.css";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { AccordionItem } from "react-bootstrap";
export default function LeftMenuBar() {
  const { SubMenu } = Menu;
  const [collapsed, setcollapsed] = useState(true);
  useEffect(() => {
    // alert(collapsed)
  }, [collapsed]);
  const toggleCollapsed = () => {
    setcollapsed(!collapsed);
  };
  function handleClick(e) {
    // console.log('click', e);
  }
  return (
    <div>
      <div style={{ background: "#2B3A4A" }}>
        <Menu
          defaultOpenKeys={["sub1"]}
          mode="inline"
          style={{ background: "#2B3A4A", color: "white" }}
          inlineCollapsed={collapsed}
        >
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Menu</Accordion.Header>
              <Accordion.Body className="p-0">
                <Menu.Item
                  className="main-menu m-0 ps-3 pe-0"
                  key="1"
                  style={{ background: "#2B3A4A", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/menu/MenuBarSetting">
                    Menu Bar Logo Setting
                  </Link>
                </Menu.Item>
                <Menu.Item
                  className="main-menu m-0 ps-3 pe-0"
                  key="2"
                  style={{ background: "#2B3A4A", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/menu/MenuLogoSetting">
                    Menu Bar Add or Delete Setting
                  </Link>
                </Menu.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Menu.Item
            className="main-menu"
            key="3"
            icon={<DesktopOutlined />}
            style={{ background: "none", borderBottom: "none" }}
          >
            <Link className="menu-item" to="/add-hero-slider">
              Add Hero
            </Link>
          </Menu.Item>
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Home Page</Accordion.Header>
              <AccordionBody className="p-0">
                <Menu.Item className="m-0 ps-3 pe-0" key="4">
                  <Link
                    className="menu-item"
                    to="/home/About"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    About
                  </Link>
                </Menu.Item>
                <Menu.Item className="m-0 ps-3 pe-0" key="5">
                  <Link
                    className="menu-item"
                    to="/home/Portfolio"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    Portfolio
                  </Link>
                </Menu.Item>
                <Menu.Item className="m-0 ps-3 pe-0" key="6">
                  <Link
                    className="menu-item"
                    to="/home/Fun-Fact"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    Fun Fact
                  </Link>
                </Menu.Item>
                <Menu.Item className="m-0 ps-3 pe-0" key="7">
                  <Link
                    className="menu-item"
                    to="/home/Trusted-Client"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    Trusted Client
                  </Link>
                </Menu.Item>
                <Menu.Item className="m-0 ps-3 pe-0" key="8">
                  <Link
                    className="menu-item"
                    to="/home/Team-Carousal"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    Team Carousal
                  </Link>
                </Menu.Item>
                <Menu.Item className="m-0 ps-3 pe-0" key="9">
                  <Link
                    className="menu-item"
                    to="/home/Client-Carousal"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    Client Carousal
                  </Link>
                </Menu.Item>
                <Menu.Item className="m-0 ps-3 pe-0" key="10">
                  <Link
                    className="menu-item"
                    to="/home/Quote"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    Quote
                  </Link>
                </Menu.Item>
                <Menu.Item className="m-0 ps-3 pe-0" key="11">
                  <Link
                    className="menu-item"
                    to="/home/Experience"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    Experience
                  </Link>
                </Menu.Item>
                <Menu.Item className="m-0 ps-3 pe-0" key="12">
                  <Link
                    className="menu-item"
                    to="/home/Services"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    Services
                  </Link>
                </Menu.Item>
                <Menu.Item className="m-0 ps-3 pe-0" key="13">
                  <Link
                    className="menu-item"
                    to="/home/Blog"
                    style={{ background: "none", borderBottom: "none" }}
                  >
                    Blog
                  </Link>
                </Menu.Item>
              </AccordionBody>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Contact Page</Accordion.Header>
              <Accordion.Body className="p-0">
                <Menu.Item
                  className="main-menu m-0 ps-3 pe-0"
                  key="14"
                  style={{ background: "none", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/contact/ContactUsPage">
                    Contact Us Page Setting
                  </Link>
                </Menu.Item>
                <Menu.Item
                  className="main-menu m-0 ps-3 pe-0"
                  key="15"
                  style={{ background: "none", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/contact/ContactUsQuery">
                    Contact Us Query List
                  </Link>
                </Menu.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>About Page</Accordion.Header>
              <Accordion.Body className="p-0">
                <Menu.Item
                  className="m-0 ps-3 pe-0"
                  key="16"
                  style={{ background: "none", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/about/GetToKnow">
                    Get To Know Section
                  </Link>
                </Menu.Item>
                <Menu.Item
                  className="m-0 ps-3 pe-0"
                  key="17"
                  style={{ background: "none", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/about/Testimonial">
                    Testimonial Section
                  </Link>
                </Menu.Item>
                <Menu.Item
                  className="m-0 ps-3 pe-0"
                  key="18"
                  style={{ background: "none", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/about/WhyToChooseUs">
                    Why To Choose US Setting
                  </Link>
                </Menu.Item>
                <Menu.Item
                  className="m-0 ps-3 pe-0"
                  key="18"
                  style={{ background: "none", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/about/addTeam">
                    Add Team member
                  </Link>
                </Menu.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* careers page:-- start */}
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Careers</Accordion.Header>
              <Accordion.Body className="p-0">
                <Menu.Item
                  className="m-0 ps-3 pe-0"
                  key="27"
                  style={{ background: "none", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/careers/create-job">
                    Create Job
                  </Link>
                </Menu.Item>
                <Menu.Item
                  className="m-0 ps-3 pe-0"
                  key="28"
                  style={{ background: "none", borderBottom: "none" }}
                >
                  <Link className="menu-item" to="/careers/job-applications">
                    Job Applications
                  </Link>
                </Menu.Item>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* careers page:-- end */}

          <Menu.Item
            key="19"
            icon={<SettingOutlined />}
            style={{ background: "none", borderBottom: "none" }}
          >
            <Link className="menu-item" to="/blog-page-setting">
              Blog Page
            </Link>
          </Menu.Item>
          <Menu.Item
            key="19"
            icon={<SettingOutlined />}
            style={{ background: "none", borderBottom: "none" }}
          >
            <Link className="menu-item" to="/customer-page-setting">
              Customer Page
            </Link>
          </Menu.Item>
          <Menu.Item
            key="19"
            icon={<SettingOutlined />}
            style={{ background: "none", borderBottom: "none" }}
          >
            <Link className="menu-item" to="/impact-page-setting">
              Impact Page
            </Link>
          </Menu.Item>
          <Menu.Item
            key="19"
            icon={<SettingOutlined />}
            style={{ background: "none", borderBottom: "none" }}
          >
            <Link className="menu-item" to="/engagement-page-setting">
              Engagement Page
            </Link>
          </Menu.Item>
          <Menu.Item
            key="20"
            icon={<FileImageOutlined />}
            style={{ background: "none", borderBottom: "none" }}
          >
            <Link className="menu-item" to="/page-banner">
              Page Banner
            </Link>
          </Menu.Item>
          <Menu.Item
            key="21"
            icon={<FileImageOutlined />}
            style={{ background: "none", borderBottom: "none" }}
          >
            <Link className="menu-item" to="/footer-setting">
              Footer
            </Link>
          </Menu.Item>
          <Menu.Item
            key="22"
            icon={<FileImageOutlined />}
            style={{ background: "none", borderBottom: "none" }}
          >
            <Link className="menu-item" to="/portfolio-setting">
              Portfolio
            </Link>
          </Menu.Item>
          <Menu.Item
            key="23"
            icon={<FileImageOutlined />}
            style={{ background: "none", borderBottom: "none" }}
          >
            <Link className="menu-item" to="/services-setting">
              Services
            </Link>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
}
