import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { FaSuitcase } from "react-icons/fa";
import { FaWallet } from "react-icons/fa";

const JobApplicationViewMore = ({ jobDetails }) => {
  const [job, setJob] = useState("");
  useEffect(() => {
    // set job from the incoming props
    setJob(jobDetails);
  }, [jobDetails]);
  return (
    <>
      {job ? (
        <div className="col-12 ">
          <div
            className="w-full"
            style={{ paddingTop: "5px", paddingBottom: "10px" }}
          >
            <div className="w-full" key={job._id}>
              <div
                style={{
                  borderRadius: "10px",
                }}
                className=" w-100  "
              >
                <div className="my-auto">
                  <div className="content  w-full  d-flex flex-column">
                    {job.jobTitle && (
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        <span style={{ fontWeight: "bold", color: "#393030" }}>
                          Post:
                        </span>{" "}
                        {job.jobTitle}
                      </p>
                    )}
                    {/* job details:- location, type, salary */}
                    <ul
                      className="list-unstyled"
                      style={{
                        fontSize: "14px",
                        color: "gray",
                        lineHeight: "30px",
                      }}
                    >
                      <li
                        style={{
                          paddingInline: "10px",
                          paddingTop: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FaLocationDot />
                        <p
                          className="my-auto"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#393030",
                          }}
                        >
                          {job.jobLocation}
                        </p>
                      </li>
                      <li
                        style={{
                          paddingInline: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FaSuitcase />
                        <p
                          className="my-auto"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#393030",
                          }}
                        >
                          {job.jobType}
                        </p>
                      </li>
                      <li
                        style={{
                          paddingInline: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FaWallet />
                        <p
                          className="my-auto"
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#393030",
                          }}
                        >
                          {job.jobSalary}
                        </p>
                      </li>
                    </ul>
                    {/* job category/role */}
                    {job.jobCategory && (
                      <p
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          textAlign: "justify",
                        }}
                        className="pt-2 pb-2"
                      >
                        <span style={{ fontWeight: "bold", color: "#393030" }}>
                          Role:
                        </span>{" "}
                        {job.jobCategory}
                      </p>
                    )}
                    {/* experience  */}
                    {job.jobExperience && (
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#393030",
                          }}
                        >
                          Experince:
                        </span>{" "}
                        {job.jobExperience}
                      </p>
                    )}

                    {/* Education Requirements  */}
                    {job.jobEducation && (
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#393030",
                          }}
                        >
                          Edu Req:
                        </span>{" "}
                        {job.jobEducation}
                      </p>
                    )}

                    {/* job description */}
                    {job.jobDescription && (
                      <p
                        style={{
                          fontSize: "14px",
                          color: "black",
                          textAlign: "justify",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#393030",
                          }}
                        >
                          Des:
                        </span>{" "}
                        {job.jobDescription}
                      </p>
                    )}

                    {/* Skills Requirements  */}
                    {job.jobSkills && (
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#393030",
                          }}
                        >
                          Skills:
                        </span>{" "}
                        {job.jobSkills}
                      </p>
                    )}
                  </div>
                  <div className="footer">
                    {job.jobMessage && (
                      <p
                        style={{
                          fontSize: "14px",
                          color: "black",
                          fontStyle: "italic",
                          textAlign: "justify",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "black",
                          }}
                        >
                          Note:
                        </span>{" "}
                        {job.jobMessage}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "No Requirement Found"
      )}
    </>
  );
};

export default JobApplicationViewMore;
