import { Card ,Tabs } from 'antd'
import React from 'react'
import ImpactSetting from '../Component/ImpactengSettings';
export default function ImpactPageSetting() {
    const { TabPane } = Tabs;

    function callback(key) {
        // console.log(key);
      }
  return (
    <div>
        <Card>
        <ImpactSetting/>
        </Card>
    </div>
  )
}
