import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Layout, Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import LoginReducer from "./State/loginReducer";
import { LoginAction, LogoutAction } from "./State/authAction";
import { Routes, Link, Route, useNavigate } from "react-router-dom";
import LeftMenuBar from "./Component/LeftMenuBar";
import LoginForm from "./Component/LoginForm";
import Home from "./Pages/Home";
import PageNotFound from "./Component/PageNotFound";
import AddHeroSlider from "./Pages/AddHeroSlider";
import HomePageSetting from "./Pages/HomePageSetting";
import ContactPageSetting from "./Pages/ContactPageSetting";
import AboutPageSetting from "./Pages/AboutPageSetting";
import BlogPageSetting from "./Pages/BlogPageSetting";
import CustomerPageSetting from "./Pages/Valuedcustomer";
import PageBanner from "./Pages/PageBanner";
import FooterPageSetting from "./Pages/FooterPageSetting";
import MenuBarSetting from "./Pages/MenuBarSetting";
import PortfolioSetting from "./Pages/PortfolioSetting";
import ServicesSetting from "./Pages/ServicesSetting";
import Test from "./Component/Test";
import { Row, Col } from "react-bootstrap";
import Logo from "../src/Images/logo.png";
import "../src/assets/css/mystyle.css";
import AboutSection from "./Component/HomeSettingComponent/AboutSection";
import PortfolioSection from "./Component/HomeSettingComponent/PortfolioSection";
import FunFactSection from "./Component/HomeSettingComponent/FunFactSection";
import TrustedClientSection from "./Component/HomeSettingComponent/TrustedClientSection";
import TeamCarousalSection from "./Component/HomeSettingComponent/TeamCarousalSection";
import ClientCarousalSection from "./Component/HomeSettingComponent/ClientCarousalSection";
import QuoteSection from "./Component/HomeSettingComponent/QuoteSection";
import ExperienceSection from "./Component/HomeSettingComponent/ExperienceSection";
import ServiceSection from "./Component/HomeSettingComponent/ServiceSection";
import BlogSection from "./Component/HomeSettingComponent/BlogSection";
import MenuBarSettingSection from "./Component/MenuBarComponent/MenuBarSettingSection";
import MenuLogoSection from "./Component/MenuBarComponent/MenuLogoSection";
import ContactUsSettingSection from "./Component/ConatctSettingComponent/ContactUsSettingSection";
import ContactUsQuerySection from "./Component/ConatctSettingComponent/ContactUsQuerySection";
import GetToKnowSection from "./Component/AboutSettingComponent/getToKnowSection";
import TestimonialSection from "./Component/AboutSettingComponent/TestimonialSection";
import WhyToChooseUsSection from "./Component/AboutSettingComponent/WhyToChooseUsSection";
import AddTeam from "./Pages/AddTeam";
import AddJOb from "./Pages/AddJob";
import JobApplications from "./Pages/JobApplications";
import ImpactPageSetting from "./Pages/Impacteng";
import EngagementPageSetting from "./Pages/Engagement";

function App() {
  const { Header, Footer, Sider, Content } = Layout;
  const [login, setLogin] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ReceiveData = useSelector((state) => ({ ...state.LoginReducer }));
  useEffect(() => {
    setLogin(ReceiveData.isLogin);
    console.log("hello revolution ", login);
  }, [ReceiveData]);

  const Logout = { marginTop: "7px" };
  return (
    <>
      {login ? (
        <div>
          <Layout>
            <header
              style={{
                color: "white",
                display: "flex",
                alignItems: "center",
                background: "#2B3A4A",
                height: "68px",
              }}
            >
              <Row
                style={{ justifyContent: "space-between" }}
                className="w-100"
              >
                <Col className="text-center" lg={2}>
                  <img className="w-100 logo " src={Logo} alt="logo" />
                </Col>
                <Col lg={10}>
                  <div className="text-end">
                    <Button
                      style={Logout}
                      onClick={() => {
                        dispatch(LogoutAction());
                        navigate("/");
                        message.success("Logout successful");
                      }}
                    >
                      Logout
                    </Button>
                  </div>
                </Col>
              </Row>
            </header>
            <Layout>
              <Sider style={{ backgroundColor: "#001529" }}>
                <LeftMenuBar />
              </Sider>
              <Content>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/add-hero-slider" element={<AddHeroSlider />} />
                  <Route
                    path="/home-page-setting"
                    element={<HomePageSetting />}
                  />
                  <Route
                    path="/contact-page-setting"
                    element={<ContactPageSetting />}
                  />
                  <Route
                    path="/about-page-setting"
                    element={<AboutPageSetting />}
                  />
                  <Route
                    path="/blog-page-setting"
                    element={<BlogPageSetting />}
                  />
                  <Route
                    path="/customer-page-setting"
                    element={<CustomerPageSetting />}
                  />
                  <Route
                    path="/impact-page-setting"
                    element={<ImpactPageSetting/>}
                  />
                   <Route
                    path="/engagement-page-setting"
                    element={<EngagementPageSetting/>}
                  />
                  <Route path="/page-banner" element={<PageBanner />} />
                  <Route
                    path="/footer-setting"
                    element={<FooterPageSetting />}
                  />
                  <Route path="/menu-setting" element={<MenuBarSetting />} />
                  <Route
                    path="/portfolio-setting"
                    element={<PortfolioSetting />}
                  />
                  <Route
                    path="/services-setting"
                    element={<ServicesSetting />}
                  />
                  {/* menu bar left-side bar routes starts here */}
                  <Route
                    path="/menu/MenuBarSetting"
                    element={<MenuBarSettingSection />}
                  />
                  <Route
                    path="/menu/MenuLogoSetting"
                    element={<MenuLogoSection />}
                  />

                  {/* menu bar left-side bar routes starts here  */}

                  {/* Home page  left-side bar routes starts here */}
                  <Route path="/home/About" element={<AboutSection />} />
                  <Route
                    path="/home/Portfolio"
                    element={<PortfolioSection />}
                  />
                  <Route path="/home/Fun-Fact" element={<FunFactSection />} />
                  <Route
                    path="/home/Trusted-Client"
                    element={<TrustedClientSection />}
                  />
                  <Route
                    path="/home/Team-Carousal"
                    element={<TeamCarousalSection />}
                  />
                  <Route
                    path="/home/Client-Carousal"
                    element={<ClientCarousalSection />}
                  />
                  <Route path="/home/Quote" element={<QuoteSection />} />
                  <Route
                    path="/home/Experience"
                    element={<ExperienceSection />}
                  />
                  <Route path="/home/Services" element={<ServiceSection />} />
                  <Route path="/home/Blog" element={<BlogSection />} />

                  {/* Home left-side bar routes ended here */}
                  {/* Contact us page  left-side bar routes ended here         */}
                  <Route
                    path="/contact/ContactUsPage"
                    element={<ContactUsSettingSection />}
                  />
                  <Route
                    path="/contact/ContactUsQuery"
                    element={<ContactUsQuerySection />}
                  />
                  {/* Contact us page  left-side bar routes ended here  */}

                  {/* About page  left-side bar routes starts here */}
                  <Route
                    path="/about/GetToKnow"
                    element={<GetToKnowSection />}
                  />
                  <Route
                    path="/about/Testimonial"
                    element={<TestimonialSection />}
                  />

                  <Route
                    path="/about/WhyToChooseUs"
                    element={<WhyToChooseUsSection />}
                  />

                  <Route path="/about/addTeam" element={<AddTeam />} />

                  {/* About us page  left-side bar routes ended here */}

                  {/* <Route path="*" element={<PageNotFound />} /> */}
                  {/* <Route path="/test" element={< Test/>} /> */}
                  <Route path="/careers/create-job" element={<AddJOb />} />
                  <Route
                    path="/careers/job-applications"
                    element={<JobApplications />}
                  />
                </Routes>
              </Content>
            </Layout>
            {/* <Footer style={{ color: "white", backgroundColor: "#001529" ,textAlign:"center"}}>Footer</Footer> */}
          </Layout>
        </div>
      ) : (
        <div>
          <LoginForm />
        </div>
      )}
    </>
  );
}

export default App;
