import React from "react";
import { Card, Tabs } from "antd";
import CreateJob from "../Component/CreateJob";

const AddJOb = () => {
  const { TabPane } = Tabs;
  function callback(key) {
    //   console.log(key);
  }
  return (
    <div>
      <Card>
        <Tabs defaultActiveKey="1" onChange={callback} size="large" centered>
          {/* <TabPane tab="Portfolio Page  Setting" key="1">
        <MenuLogoSection/>
        </TabPane> */}

          <TabPane tab="Generate Employment" key="2">
            <CreateJob />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default AddJOb;
