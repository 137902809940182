import React, { useState, useEffect } from 'react';
import { Card, Table, Space, message } from 'antd';
import { Button, Form } from 'react-bootstrap';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import api from "../Helper/API";
import CKEditor from "react-ckeditor-component";


export default function ImpactSettingComponent() {
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("");
    const [cardTitle, setCardTitle] = useState("");
    const [description, setDescription] = useState("");
    const [cardDescription, setcardDescription] = useState("");
    const [emoji, setEmoji] = useState("");
    const [impact_id, setImpactId] = useState(null);
    const [refresh, setRefresh] = useState("start");
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        const fetchimpact = async () => {
            try {
                const res = await api.post("/getImpact");
                setData(res.data.data);
                console.log(res.data.data);

            } catch (error) {
                console.error("Error fetching customers:", error);
            }
        };
        fetchimpact();
    }, [refresh]);


    const handleSubmit = async () => {
        try {

            if (edit) {
                const result = await api.post("/updateImpact", { title, cardTitle, emoji, description, cardDescription, impact_id }, {
                    headers: { "Content-Type": "application/json" },
                });
                message.success(result.data.message);
                console.log(result);

            } else {
                const result = await api.post("/addImpact", { title, cardTitle, emoji, description, cardDescription }, {
                    headers: { "Content-Type": "application/json" },

                });
                message.success(result.data.message);
            }

            setTitle("");
            setCardTitle("");
            setEmoji("")
            setDescription("");
            setcardDescription("")
            setEdit(false);
            setRefresh(prev => prev + 1);
        } catch (error) {
            message.error("Error saving customer");
            console.error(error);
        }
    };


    const handleEdit = (data) => {
        setTitle(data.title);
        setCardTitle(data.cardTitle)
        setEmoji(data.emoji)
        setDescription(data.description)
        setcardDescription(data.cardDescription)
        setImpactId(data._id);
        setEdit(true);
    };

    const handleDelete = async (data) => {
        try {
            const result = await api.post("/updateImpact", { impact_id: data._id, delete_status: true });
            console.log("eeeeeeee", result);

            message.success(result.data.message);
            setRefresh(prev => prev + 1);
        } catch (error) {
            message.error("Error deleting customer");
            console.error(error);
        }
    };
    function onDescriptionEditorContentChange(evt) {
        console.log("onChange fired with event info: ", evt);
        var newContent = evt.editor.getData();
        setcardDescription(newContent);
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Emoji',
            dataIndex: 'emoji',
            key: 'emoji',
        },
        {
            title: 'CardTitle',
            dataIndex: 'cardTitle',
            key: 'cardTitle',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button variant='warning' onClick={() => handleEdit(record)}><EditOutlined /></Button>
                    <Button variant='danger' onClick={() => handleDelete(record)}><DeleteOutlined /></Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Card title="Add Impact">
                <Form>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter Heading" />
                        <Form.Control value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter SubHeading" />
                        <Form.Control value={emoji} onChange={(e) => setEmoji(e.target.value)} placeholder="Enter Your Emoji😊" />
                        <Form.Control value={cardTitle} onChange={(e) => setCardTitle(e.target.value)} placeholder="Enter cardTitle" />
                        {/* <Form.Control value={cardDescription} onChange={(e) => setcardDescription(e.target.value)} placeholder="Enter cardDescription" /> */}
                        <div style={{ margin: "1%" }}>
                            <CKEditor
                                activeClass="p10"
                                content={cardDescription}
                                events={{
                                    // "blur": onBlur,
                                    // "afterPaste": afterPaste,
                                    change: onDescriptionEditorContentChange,
                                }}
                            />{" "}
                        </div>
                        <Button variant='success' onClick={handleSubmit}>{edit ? "UPDATE" : "ADD"}</Button>
                    </div>
                </Form>
            </Card>

            <Card title="Impact Card List">
                <Table dataSource={data} columns={columns} />
            </Card>
        </div>
    );
}


