import React, { useState, useEffect } from 'react';
import { Card, Table, Space, message } from 'antd';
import { Button, Form } from 'react-bootstrap';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import api from "../Helper/API";
import CKEditor from "react-ckeditor-component";


export default function BlogSettingComponent() {
  const [data, setData] = useState([]);
  const [blogImage, setBlogImage] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [writer, setWriter] = useState("");
  const [status, setStatus] = useState(false); 
  const [blogId, setBlogId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.post("/getBlog");
      setData(res.data.data);
    };
    fetchData();
  }, [refresh]);

  const handleFileChange = (e, type) => {
    if (type === "blog") {
      setBlogImage(e.target.files[0]);
    } else {
      setThumbnailImage(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    if(blogImage) formData.append("blog_image", blogImage);
    if(thumbnailImage) formData.append("thumbnail_image", thumbnailImage);
    formData.append("writer", writer);
    formData.append("title", title);
    formData.append("content", content);
    formData.append("status", status);

    if (edit) {
      formData.append("blog_id", blogId);
    }

    try {
      const result = await api.post(edit ? "/updateBlog" : "/addBlog", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success(result.data.message);
      resetForm();
      setRefresh(!refresh);
    } catch (error) {
      message.error("Something went wrong!");
    }
  };

  const handleEdit = (data) => {
    setBlogId(data._id);
    setTitle(data.title);
    setWriter(data.writer);
    setContent(data.content);
    setStatus(data.status); 
    setEdit(true);
  };

  const handleDelete = async (data) => {
    await api.post("/updateBlog", { blog_id: data._id, delete_status: true });
    message.success("Blog deleted successfully!");
    setRefresh(!refresh);
  };

  const resetForm = () => {
    setBlogImage(null);
    setThumbnailImage(null);
    setTitle("");
    setContent("");
    setWriter("");
    setStatus(false);
    setEdit(false);
  };
  function onDescriptionEditorContentChange(evt) {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setContent(newContent);
  }
  const columns = [
    {
      title: 'thumbnail_image',
      dataIndex: 'thumbnail_image',
      key: 'thumbnail_image',
      render: (text) => text ? <img src={text} alt="thumbnail" style={{ width: 50, height: 50 }} /> : "No Image",
    },
    {
      title: 'Blog Image',
      dataIndex: 'blog_image',
      key: 'blog_image',
      render: (text) => text ? <img src={text} alt="blog" style={{ width: 50, height: 50 }} /> : "No Image",
    },
    { title: "Title", dataIndex: "title", key: "title", render: text => text.substring(0, 20) },
    // { title: "Writer", dataIndex: "writer", key: "writer" },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => status ? "Published" : "Unpublished"
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button variant="success" onClick={() => handleEdit(record)}><EditOutlined /></Button>
          <Button variant="danger" onClick={() => handleDelete(record)}><DeleteOutlined /></Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Card title="Add/Edit Blog">
        <Form>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Form.Control type="file" onChange={(e) => handleFileChange(e, "thumbnail")} />
          <Form.Control type="file" onChange={(e) => handleFileChange(e, "blog")}  />
          <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
          <Form.Control value={writer} onChange={(e) => setWriter(e.target.value)} placeholder="Writer" />
          {/* <Form.Control as="textarea" value={content} onChange={(e) => setContent(e.target.value)} placeholder="Content" /> */}
            <div style={{ margin: "1%" }}>
                          <CKEditor
                            activeClass="p10"
                            content={content}
                            events={{
                              // "blur": onBlur,
                              // "afterPaste": afterPaste,
                              change: onDescriptionEditorContentChange,
                            }}
                          />{" "}
                        </div>
          <Form.Check
            type="checkbox"
            label="Publish"
            checked={status}
            onChange={(e) => setStatus(e.target.checked)}
          />
          <Button variant="success" onClick={handleSubmit}>{edit ? "Update" : "Add"}</Button>
          </div>

        </Form>
      </Card>

      <Card title="Blogs List">
        <Table dataSource={data} columns={columns} rowKey="_id" />
      </Card>
    </div>
  );
}

