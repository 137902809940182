import React, { useState, useEffect } from "react";
import { Card, Table, Space, message } from "antd";
import { Button, Form } from "react-bootstrap";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import api from "../Helper/API";
import { Buffer } from "buffer";
window.Buffer = window.Buffer || Buffer;

export default function CreateJob() {
  // required
  const [jobTitle, setJObTitle] = useState("");
  const [jobCategory, setJobCategory] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobSalary, setJobSalary] = useState("");
  const [jobExperience, setJobExperience] = useState("");
  const [jobEducation, setJobEducation] = useState("");
  const [jobSkills, setJobSkills] = useState("");
  const [jobMessage, setJobMessage] = useState("");
  const [data1, setData1] = useState("");
  const [edit, setEdit] = useState(false);
  const [jobId, setJobId] = useState("");

  const getAllJobs = async () => {
    try {
      const res = await api.get("/get-new-job");
      setData1(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    getAllJobs();
  }, []);

  const handleSubmit = async (e) => {
    if (edit) {
      let apiData = {
        jobTitle: jobTitle,
        jobCategory: jobCategory,
        jobDescription: jobDescription,
        jobLocation: jobLocation,
        jobType: jobType,
        jobSalary: jobSalary,
        jobExperience: jobExperience,
        jobEducation: jobEducation,
        jobSkills: jobSkills,
        jobMessage: jobMessage,
        isJobActive: true,
      };
      console.log("job id is", jobId);
      const result = await api.post(`/update-new-job/${jobId}`, apiData);
      message.success(result.data.message);
      setJObTitle("");
      setJobCategory("");
      setJobDescription("");
      setJobLocation("");
      setJobType("");
      setJobSalary("");
      setJobExperience("");
      setJobEducation("");
      setJobSkills("");
      setJobMessage("");
      getAllJobs();
    } else {
      let apiData = {
        jobTitle: jobTitle,
        jobCategory: jobCategory,
        jobDescription: jobDescription,
        jobLocation: jobLocation,
        jobType: jobType,
        jobSalary: jobSalary,
        jobExperience: jobExperience,
        jobEducation: jobEducation,
        jobSkills: jobSkills,
        jobMessage: jobMessage,
        isJobActive: true,
      };
      const result = await api.post("/add-new-job", apiData);
      message.success(result.data.message);
      setJObTitle("");
      setJobCategory("");
      setJobDescription("");
      setJobLocation("");
      setJobType("");
      setJobSalary("");
      setJobExperience("");
      setJobEducation("");
      setJobSkills("");
      setJobMessage("");
      getAllJobs();
    }
    // e.preventDefault();
  };

  const handleEdit = (data) => {
    setJObTitle(data.jobTitle);
    setJobCategory(data.jobCategory);
    setJobDescription(data.jobDescription);
    setJobLocation(data.jobLocation);
    setJobType(data.jobType);
    setJobSalary(data.jobSalary);
    setJobExperience(data.jobExperience);
    setJobEducation(data.jobEducation);
    setJobSkills(data.jobSkills);
    setJobMessage(data.jobMessage);
    setJobId(data._id);
  };

  const handleJobStatus = async (data, status) => {
    const result = await api.post(`/update-new-job/${data._id}`, {
      job_status: true,
      isJobActive: status,
    });
    message.success(result.data.message);
    getAllJobs();
    console.log(result);
  };

  const columns1 = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => {
        const date = new Date(text);
        return date.toLocaleString(); // This will use the default locale and format
      },
    },
    {
      title: "Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: (text) => text.substring(0, 20),
    },
    {
      title: "Category",
      dataIndex: "jobCategory",
      key: "jobCategory",
      render: (text) => text.substring(0, 20),
    },
    // {
    //   title: "Description",
    //   dataIndex: "jobDescription",
    //   key: "jobDescription",
    //   render: (text) => text.substring(0, 20),
    // },
    // {
    //   title: "Location",
    //   dataIndex: "jobLocation",
    //   key: "jobLocation",
    //   render: (text) => text.substring(0, 20),
    // },
    {
      title: "Job Type",
      dataIndex: "jobType",
      key: "jobType",
      render: (text) => text.substring(0, 20),
    },
    {
      title: "Salary",
      dataIndex: "jobSalary",
      key: "jobSalary",
      render: (text) => text.substring(0, 20),
    },
    {
      title: "Experience",
      dataIndex: "jobExperience",
      key: "jobExperience",
      render: (text) => text.substring(0, 20),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a>
            <Button
              variant="danger"
              onClick={() => {
                handleEdit(text);
                setEdit(true);
              }}
            >
              <EditOutlined />
            </Button>
          </a>
          <a>
            <Button
              variant={`${text.isJobActive ? "success" : "danger"}`}
              onClick={() => {
                handleJobStatus(text, text.isJobActive ? false : true);
              }}
            >
              {text.isJobActive ? "Active" : "Inactive"}
            </Button>
          </a>
          {/* <a><Button onClick={handleDelete(text)}>Edit</Button></a> */}
        </Space>
      ),
    },
  ];

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const inputstyle = { width: "44%", margin: "1%" };
  return (
    <div>
      {/* form for add hero slider   */}
      <Card title=" Add/Edit a Job">
        <Form>
          <div style={outerstyle}>
            {/* job title  */}
            <Form.Control
              style={inputstyle}
              value={jobTitle}
              onChange={(e) => {
                setJObTitle(e.target.value);
              }}
              placeholder="Title (like: Data Scientist)..."
            />

            {/*job category*/}
            <Form.Control
              style={inputstyle}
              value={jobCategory}
              onChange={(e) => {
                setJobCategory(e.target.value);
              }}
              placeholder="Category (like: Admin / Secretarial / Office Support)..."
            />

            {/*job description*/}
            <Form.Control
              style={inputstyle}
              value={jobDescription}
              onChange={(e) => {
                setJobDescription(e.target.value);
              }}
              placeholder="Description like: Looking for a newly qualified Data Scientist..."
            />

            {/*job location*/}
            <Form.Control
              style={inputstyle}
              value={jobLocation}
              onChange={(e) => {
                setJobLocation(e.target.value);
              }}
              placeholder="Location like: Bapu nagar, jaipur..."
            />

            {/* job type */}
            <Form.Control
              style={inputstyle}
              value={jobType}
              onChange={(e) => {
                setJobType(e.target.value);
              }}
              placeholder="Job Type like: Permanent / Full Time..."
            />

            {/* job salary */}
            <Form.Control
              style={inputstyle}
              value={jobSalary}
              onChange={(e) => {
                setJobSalary(e.target.value);
              }}
              placeholder="Salary like: ₹2,40,000 - ₹3,80,000 per year..."
            />

            {/* job experience */}
            <Form.Control
              style={inputstyle}
              value={jobExperience}
              onChange={(e) => {
                setJobExperience(e.target.value);
              }}
              placeholder="Experience like: 3-4 Years..."
            />
            {/* job education */}
            <Form.Control
              style={inputstyle}
              value={jobEducation}
              onChange={(e) => {
                setJobEducation(e.target.value);
              }}
              placeholder="Education like: B.tech(EE), M.sc(Electronics)..."
            />
            {/* job skills */}
            <Form.Control
              style={inputstyle}
              value={jobSkills}
              onChange={(e) => {
                setJobSkills(e.target.value);
              }}
              placeholder="Skills like: Excel, MSWord etc...."
            />

            <Form.Control
              as="textarea"
              style={{ width: "90%", margin: "1%" }}
              value={jobMessage}
              onChange={(e) => setJobMessage(e.target.value)}
              placeholder="Enter message to job seeker..."
            />
          </div>
          <div>
            {" "}
            <Button
              variant="success"
              style={{ margin: "1%", textAlign: "center" }}
              onClick={handleSubmit}
            >
              {edit ? "Update" : "ADD"}
            </Button>
          </div>
        </Form>
      </Card>

      <Card title="List of Current JObs ">
        <Table dataSource={data1} columns={columns1}></Table>
      </Card>
    </div>
  );
}
