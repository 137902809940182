import JobApplicantView from "../Component/JobApplicantView";
import api from "../Helper/API";
import React, { useState, useEffect } from "react";

const JobApplications = () => {
  const [data1, setData1] = useState("");
  const [filteredArray, setFilteredArray] = useState([]);

  const FilterData = (data) => {
    let tempArr = [];
    data.forEach((item) => {
      if (tempArr.length === 0) {
        let pushData = {
          id: item.job_details._id,
          data: [item],
        };
        tempArr.push(pushData);
      } else {
        let flag = true;
        tempArr.forEach((val) => {
          if (val.id == item.job_details._id) {
            val.data.push(item);
            flag = false;
            return;
          }
        });

        if (flag) {
          let pushData = {
            id: item.job_details._id,
            data: [item],
          };
          tempArr.push(pushData);
        }
      }
    });
    // console.log("Temp Arr is :-", tempArr);
    setFilteredArray(tempArr);
  };

  const getApplication = async () => {
    try {
      const res = await api.get("/get-all-applications");
      console.log("Applications are :---", res);
      setData1(res.data.data);
      FilterData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(async () => {
    getApplication();
  }, []);
  return (
    <>
      <div style={{ width: "96%", margin: "4px auto" }}>
        {filteredArray ? (
          filteredArray.map((item, index) => {
            return (
              <JobApplicantView
                applicationData={item}
                onCallBack={() => getApplication()}
              />
            );
          })
        ) : (
          <div>No Application Yet</div>
        )}
      </div>
    </>
  );
};

export default JobApplications;
