import { Card ,Tabs } from 'antd'
import React from 'react'
import EngagementSetting from '../Component/EngagementSettings';
export default function EngagementPageSetting() {
    const { TabPane } = Tabs;

    function callback(key) {
        // console.log(key);
      }
  return (
    <div>
        <Card>
        <EngagementSetting/>
        </Card>
    </div>
  )
}
