import React, { useState, useEffect } from 'react';
import { Card, Table, Space, message } from 'antd';
import { Button, Form } from 'react-bootstrap';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import api from "../Helper/API";

export default function CustomerSettingComponent() {
    const [data, setData] = useState([]);
    const [title, setTitle] = useState("");
    const [title2, setTitle2] = useState("");
    const [description, setDescription] = useState("");
    const [customer_id, setCustomerId] = useState(null);
    const [logoImage, setLogo] = useState(null);
    const [refresh, setRefresh] = useState("start");
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const res = await api.post("/getCustomer");
                setData(res.data.data);
                console.log(res.data.data);
                
            } catch (error) {
                console.error("Error fetching customers:", error);
            }
        };
        fetchCustomers();
    }, [refresh]);


    const handleSubmit = async () => {
        try {
            const formData = new FormData();
    
            if (logoImage) { 
                formData.append("logo_image", logoImage);
            } else {
                console.error("No image selected!");
                message.error("Please select an image!");
                return;
            }
    
            formData.append("title", title);
            formData.append("title2", title2);
            formData.append("description", description);
    
            if (edit) {
                formData.append("customer_id", customer_id);
                const result = await api.post("/updateCustomer", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                message.success(result.data.message);
            } else {
                const result = await api.post("/addCustomer", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                message.success(result.data.message);
            }

            setTitle("");
            setTitle2("");
            setDescription("");
            setLogo(null);
            setEdit(false);
            setRefresh(prev => prev + 1);
        } catch (error) {
            message.error("Error saving customer");
            console.error(error);
        }
    };
    

    const handleEdit = (data) => {
        setTitle(data.title);
        setTitle2(data.title2)
        setDescription(data.description)
        setCustomerId(data._id);
        setEdit(true);
    };

    const handleDelete = async (data) => {
        try {
            const result = await api.post("/updateCustomer", { customer_id: data._id, delete_status: true });
            message.success(result.data.message);
            setRefresh(prev => prev + 1);
        } catch (error) {
            message.error("Error deleting customer");
            console.error(error);
        }
    };

    const columns = [
        {
            title: 'Logo Image',
            dataIndex: 'logo_image',
            key: 'logo_image',
            render: text => <img src={text} alt="logo" style={{ width: 50, height: 50 }} />,
        },
        {
            title: 'ImageTitle',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Title',
            dataIndex: 'title2',
            key: 'title2',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button variant='warning' onClick={() => handleEdit(record)}><EditOutlined /></Button>
                    <Button variant='danger' onClick={() => handleDelete(record)}><DeleteOutlined /></Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Card title="Add Customer">
                <Form>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <Form.Control value={title2} onChange={(e) => setTitle2(e.target.value)} placeholder="Enter Heading" />
                        <Form.Control value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter SubHeading" />
                        <Form.Control type="file" onChange={(e) => setLogo(e.target.files[0])} />
                        <Form.Control value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter ImageTitle" />
                        <Button variant='success' onClick={handleSubmit}>{edit ? "UPDATE" : "ADD"}</Button>
                    </div>
                </Form>
            </Card>

            <Card title="Customer List">
                <Table dataSource={data} columns={columns} />
            </Card>
        </div>
    );
}




// import React, { useState, useEffect } from 'react';
// import { Card, Table, Space, message } from 'antd';
// import { Button, Form } from 'react-bootstrap';
// import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
// import axios from 'axios';
// import api from "../Helper/API"

// import { Buffer } from 'buffer';
// import { uploadFile } from '../Helper/helper';
// window.Buffer = window.Buffer || Buffer;

// export default function CustomerSettingComponent() {

//     const [data, setData] = useState([])
//     const [logo_image, setlogo_image] = useState("");
//     const [title, settitle] = useState("")
//     const [customer_id, setcustomer_id] = useState()

//     const [refresh, setRefresh] = useState("start");
//     const [edit, setEdit] = useState(false);
//     const [selectedFile, setSelectedFile] = useState(null);

//     const handleUpload1 = async () => {
//         let imgUrl = await uploadFile(selectedFile, `${selectedFile.name}fileimg_${Date.now()}`);
//         setlogo_image(imgUrl);
//         document.getElementById("f1").value = ""

//     }

//     useEffect(async () => {

//         const res = await api.post("/getCustomer");
//         console.log("c", res.data.data)
//         setData(res.data.data)
//         //   console.log("11",res)

//     }, [refresh])






//     const handleSubmit = async (e) => {
//         if (edit) {
//             console.log("true", edit)
//             const result = await api.post("/updateCustomer", { logo_image, title, customer_id })
//             message.success(result.data.message)
//             logo_image(""); settitle(""); setEdit(false)
//             setRefresh("1")

//         }
//         else {
//             console.log("add")
//             const result = await api.post("/addCustomer", { logo_image, title, })
//             // console.log(result)
//             message.success(result.data.message)
//             setlogo_image(""); settitle(""); setEdit(false)
//             setRefresh("2")
//         }
//         // e.preventDefault();
//     }

//     const handleEdit = (data) => {

//         setlogo_image(data.logo_image);
//         settitle(data.title);
//         setcustomer_id(data._id)
//         console.log(data)
//     }

//     const handleDelete = async (data) => {
//         const result = await api.post("/updateCustomer", { customer_id: data._id, delete_status: true })
//         message.success(result.data.message)
//         setRefresh("3")
//         console.log(result)
//     }


//     const columns = [
//         {
//             title: 'Logo Image URL',
//             dataIndex: 'logo_image',
//             key: 'logo_image',
//             render: text => text.substring(0, 30)

//         },

//         {
//             title: 'Title',
//             dataIndex: 'title',
//             key: 'title',
//             render: text => text.substring(0, 30)

//         },

//         {
//             title: 'Action',
//             key: 'action',
//             render: (text, record) => (
//                 <Space size="middle">
//                     <a><Button variant='danger' onClick={() => { handleEdit(text); setEdit(true); }}><EditOutlined /></Button></a>
//                     <a><Button variant='danger' onClick={() => { handleDelete(text) }}><DeleteOutlined /></Button></a>
//                     {/* <a><Button onClick={handleDelete(text)}>Edit</Button></a> */}

//                 </Space>
//             ),
//         },
//     ];

//     const outerstyle = {
//         display: "flex", justifyContent: 'flex-start', alignContent: "left",
//         flexDirection: "row", flexWrap: 'wrap'
//     }
//     const inputstyle = { width: "40%", margin: "1%", fontStyle: 'italic ' }
//     return (
//         <div>

//             {/* form for add hero slider   */}
//             <Card title="ADD Customer">
//                 <Form>
//                     <div style={outerstyle}>
//                         <Form.Control style={inputstyle} id="f1" onBlur={handleUpload1} type="file" onChange={(e) => { setSelectedFile(e.target.files[0]) }} />
//                         {/* <div style={{margin:"1%"}}><Button onClick={() => handleUpload1()}> Upload</Button></div> */}
//                         <Form.Control style={inputstyle} value={logo_image} onChange={(e) => { setlogo_image(e.target.value) }} placeholder='Enter logo Image Url' />
//                         <Form.Control as="textarea" rows={3} style={{ width: "45%", margin: "1%" }} value={title} onChange={(e) => { settitle(e.target.value) }} placeholder='Enter title' />

//                         <br />
//                         <div style={{ margin: "1%" }}><Button variant='success' style={{ margin: "1%", }} onClick={handleSubmit}>{edit ? "UPDATE" : "ADD"}</Button> </div>
//                     </div>
//                 </Form>
//             </Card>

//             <Card title="Customer List ">

//                 <Table dataSource={data} columns={columns} >

//                 </Table>

//             </Card>
//         </div>
//     )
// }
