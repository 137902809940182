import React, { useState, useEffect } from "react";
import { Card, Table, Space, message } from "antd";
import { Button, Form } from "react-bootstrap";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import api from "../Helper/API";
import { Buffer } from "buffer";
import { uploadFile } from "../Helper/helper";
import CKEditor from "react-ckeditor-component";
window.Buffer = window.Buffer || Buffer;

export default function AddTeamMember() {
  // required
  const [thumbnailImageFile, setThumbnailImageFile] = useState("");
  const [thumbnailImageUrl, setThumbnailImageUrl] = useState("");
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [userExperience, setUserExperience] = useState("");
  const [userTechStack, setUserTechStack] = useState("");
  const [userBio, setUserBio] = useState("");
  const [teammember_id, setTeammember_id] = useState("");
  const [data1, setData1] = useState([]);
  const [edit, setEdit] = useState(false);
  // social profiles
  const [userFacebook, setUserFacebook] = useState("");
  const [userLinkedin, setUserLinkdin] = useState("");
  const [userInstagram, setUserInstagram] = useState("");
  const [userX, setUserX] = useState("");

  // previosu
  const [thumbnail_image, setthumbnail_image] = useState("");

  const getTeamMemeberHandler = async () => {
    try {
      const res = await api.get("/get-team-member");
      setData1(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(async () => {
    getTeamMemeberHandler();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (edit) {
      const formData = new FormData();
      if (thumbnailImageFile) {
        formData.append("thumbnail_image", thumbnailImageFile);
      }
      // Append other form data fields (non-file data)
      formData.append("userName", userName);
      formData.append("userType", userType);
      formData.append("userDesignation", userDesignation);
      formData.append("userExperience", userExperience);
      formData.append("userTechStack", userTechStack);
      formData.append("userBio", userBio);
      formData.append("teammember_id", teammember_id);
      formData.append("userFacebook", userFacebook);
      formData.append("userLinkedin", userLinkedin);
      formData.append("userInstagram", userInstagram);
      formData.append("userX", userX);

      const result = await api.post("/update-team-member", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure this header is set
        },
      });
      message.success(result.data.message);
      setThumbnailImageFile("");
      setthumbnail_image("");
      setUserName("");
      setUserType("");
      setUserDesignation("");
      setUserExperience("");
      setUserTechStack("");
      setUserBio("");
      setUserFacebook("");
      setUserLinkdin("");
      setUserInstagram("");
      setUserX("");
      getTeamMemeberHandler();
    } else {
      const formData = new FormData();
      // Append the thumbnail image (single image)
      if (thumbnailImageFile) {
        formData.append("thumbnail_image", thumbnailImageFile);
      }
      // Append other form data fields (non-file data)
      formData.append("userName", userName);
      formData.append("userType", userType);
      formData.append("userDesignation", userDesignation);
      formData.append("userExperience", userExperience);
      formData.append("userTechStack", userTechStack);
      formData.append("userBio", userBio);
      formData.append("userFacebook", userFacebook);
      formData.append("userLinkedin", userLinkedin);
      formData.append("userInstagram", userInstagram);
      formData.append("userX", userX);
      const result = await api.post("/add-team-member", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure this header is set
        },
      });
      message.success(result.data.message);
      setThumbnailImageFile("");
      setthumbnail_image("");
      setUserName("");
      setUserType("");
      setUserDesignation("");
      setUserExperience("");
      setUserTechStack("");
      setUserBio("");
      setUserFacebook("");
      setUserLinkdin("");
      setUserInstagram("");
      setUserX("");
      getTeamMemeberHandler();
    }
    // e.preventDefault();
  };

  const handleEdit = (data) => {
    setThumbnailImageFile("");
    setThumbnailImageUrl("");
    setthumbnail_image("");
    setUserName(data.userName);
    setUserType(data.userType);
    setUserDesignation(data.userDesignation);
    setUserExperience(data.userExperience);
    setUserTechStack(data.userTechStack);
    setUserBio(data.userBio);
    setTeammember_id(data._id);
    setUserFacebook(data.userFacebook);
    setUserLinkdin(data.userLinkedin);
    setUserInstagram(data.userInstagram);
    setUserX(data.userX);
  };

  const handleDelete = async (data) => {
    const result = await api.post("/update-team-member", {
      teammember_id: data._id,
      delete_status: true,
    });
    message.success(result.data.message);
    // setRefresh("3");
    getTeamMemeberHandler();
    console.log(result);
  };

  const columns1 = [
    {
      title: "Profile Image",
      dataIndex: "thumbnail_image",
      key: "thumbnail_image",
      render: text => <img src={text} alt="logo" style={{ width: 50, height: 50 }} />,
     // render: (text) => text.substring(0, 20),
      //   render: text => <a>{text}</a>,
    },
    {
      title: "name",
      dataIndex: "userName",
      key: "userName",
      render: (text) => text.substring(0, 20),
    },
    {
      title: "Type",
      dataIndex: "userType",
      key: "userType",
      render: (text) => text.substring(0, 20),
    },
    {
      title: "Designation",
      dataIndex: "userDesignation",
      key: "userDesignation",
      render: (text) => text.substring(0, 20),
    },

    {
      title: "Experience",
      dataIndex: "userExperience",
      key: "userExperience",
      render: (number) => number,
    },
    {
      title: "Tech Stack",
      dataIndex: "userTechStack",
      key: "userTechStack",
      render: (text) => text.substring(0, 20),
    },
    {
      title: "About User",
      dataIndex: "userBio",
      key: "userBio",
      render: (text) => text.substring(0, 20),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a>
            <Button
              variant="danger"
              onClick={() => {
                handleEdit(text);
                setEdit(true);
              }}
            >
              <EditOutlined />
            </Button>
          </a>
          <a>
            <Button
              variant="danger"
              onClick={() => {
                handleDelete(text);
              }}
            >
              <DeleteOutlined />
            </Button>
          </a>
          {/* <a><Button onClick={handleDelete(text)}>Edit</Button></a> */}
        </Space>
      ),
    },
  ];

  const outerstyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  };
  const inputstyle = { width: "44%", margin: "1%" };
  return (
    <div>
      {/* form for add hero slider   */}
      <Card title=" Add Member">
        <Form onSubmit={handleSubmit}>
          <div style={outerstyle}>
            {/* profile image */}
            <Form.Control
              style={inputstyle}
              id="f1"
              type="file"
              placeholder="Select Profile photo"
              onChange={(e) => {
                setThumbnailImageFile(e.target.files[0]);
              }}
            />
            {thumbnailImageFile && (
              <img
                width={50}
                height={50}
                src={URL.createObjectURL(thumbnailImageFile)}
              />
            )}
            {/* users name */}
            <Form.Control
              style={inputstyle}
              value={userName}
              required
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              placeholder="User's Name"
            />

            {/* userType selection */}
            <Form.Control
              as="select"
              style={inputstyle}
              value={userType}
              required
              onChange={(e) => setUserType(e.target.value)}
            >
              <option value="">Select</option>
              <option value="team">Team</option>
              <option value="founder">Founder</option>
            </Form.Control>

            {/* user designation */}
            <Form.Control
              style={inputstyle}
              value={userDesignation}
              onChange={(e) => {
                setUserDesignation(e.target.value);
              }}
              required
              placeholder="Enter user's designation"
            />

            {/* user's experience */}
            {userType == "team" && (
              <Form.Control
                style={inputstyle}
                value={userExperience}
                type="number"
                min={0}
                onChange={(e) => {
                  setUserExperience(e.target.value);
                }}
                required
                placeholder="Enter user's experience"
              />
            )}

            {userType == "team" && (
              <Form.Control
                style={inputstyle}
                value={userTechStack}
                onChange={(e) => {
                  setUserTechStack(e.target.value);
                }}
                required
                placeholder="Enter user's tech-stack"
              />
            )}

            {userType == "founder" && (
              <Form.Control
                as="textarea"
                style={{ width: "90%", margin: "1%" }}
                value={userBio}
                onChange={(e) => setUserBio(e.target.value)}
                placeholder="Enter founder's Bio/Message"
              />
            )}

            {/* user's social media handles */}
            {userType == "founder" && (
              <Form.Control
                style={inputstyle}
                value={userFacebook}
                onChange={(e) => {
                  setUserFacebook(e.target.value);
                }}
                placeholder="Enter user's facebook link"
              />
            )}
            {userType == "founder" && (
              <Form.Control
                style={inputstyle}
                value={userLinkedin}
                onChange={(e) => {
                  setUserLinkdin(e.target.value);
                }}
                placeholder="Enter user's linkedin link"
              />
            )}

            {userType == "founder" && (
              <Form.Control
                style={inputstyle}
                value={userInstagram}
                onChange={(e) => {
                  setUserInstagram(e.target.value);
                }}
                placeholder="Enter user's instagram link"
              />
            )}
            {userType == "founder" && (
              <Form.Control
                style={inputstyle}
                value={userX}
                onChange={(e) => {
                  setUserX(e.target.value);
                }}
                placeholder="Enter user's X link"
              />
            )}
          </div>
          <div>
            {" "}
            <Button
              type="submit"
              variant="success"
              style={{ margin: "1%", textAlign: "center" }}
              // onClick={handleSubmit}
            >
              {edit ? "Update" : "ADD"}
            </Button>
          </div>
        </Form>
      </Card>

      <Card title="List of Members ">
        <Table dataSource={data1} columns={columns1}></Table>
      </Card>
    </div>
  );
}
